
import Vue from "vue";
import { NolaAnalytics, NolaItemId } from "@/lib/utils/analytics";
import { showNotifyDialog } from "@/lib/dialog";
import axiosBase from "axios";
import CheckboxItem from "@/components/atoms/CheckboxItem.vue";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CheckboxItem,
  },
  async created() {
    const detailRequest = await axios.get(`/agent_feature_detail`);
    if (detailRequest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "ページの一部の情報の取得に失敗しました。",
      });
    }
    const [content] = detailRequest.data.contents;
    this.detail = content;
  },
  data() {
    return {
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
      detail: { partnerCompanyImage: { url: "" }, cautions: "" },
      isAgreed: false,
      isVisibleFooter: true,
    };
  },
  props: {
    isIntro: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  updated() {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    submit() {
      this.$store.dispatch("userModule/startNolaAgentFeature");
      this.nolaAnalytics.logButtonEvent(NolaItemId.StartAgentFeature);
    },
    handleScroll() {
      const fadePosition = 1500; // この値を変更してスクロール位置を調整
      const scrollPosition = window.scrollY + window.innerHeight;

      if (scrollPosition > fadePosition) {
        this.isVisibleFooter = false;
      } else {
        this.isVisibleFooter = true;
      }
    },
    scrollBottom() {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    },
  },
});

interface Data {
  nolaAnalytics: NolaAnalytics;
  detail: { partnerCompanyImage: { url: string }; cautions: string };
  isAgreed: boolean;
  isVisibleFooter: boolean;
}

interface Methods {
  submit(): void;
  handleScroll(): void;
  scrollBottom(): void;
}

interface Computed {}

interface Props {
  isIntro: boolean;
}
